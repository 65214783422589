import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SteelHanging from "../../components/images/steel-hanging";
import HeadDownWelding from "../../components/images/head-down-welding";

function AboutPageTemplate(props) {
  return (
    <Layout>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
        <h1
          className="m-auto"
          dangerouslySetInnerHTML={{
            __html: props.currentPage.pages.h1Title
              ? props.currentPage.pages.h1Title
              : props.currentPage.title,
          }}
        />
      </div>
      <div className="max-w-2xl m-auto flex flex-wrap pb-16">
        <div className="w-full lg:w-1/2 xl:w-1/3">
          <div className="pt-2 pb-4">
            <HeadDownWelding />
          </div>
          <div className="pt-2 pb-4">
            <SteelHanging />
          </div>
        </div>
        <div
          className="w-full lg:w-1/2 xl:w-2/3 p-4 lg:px-8 lg:py-0 mb-8 text-grey-darker max-w-2xl wp-content"
          dangerouslySetInnerHTML={{
            __html: props.currentPage.content,
          }}
        />
      </div>
    </Layout>
  );
}
export default AboutPageTemplate;
