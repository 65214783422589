import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

function ProductComparisonPageTemplate({ currentPage }) {
  return (
    <Layout>
      <Seo
        title={currentPage.seo.title}
        description={currentPage.seo.metaDesc}
      />
      <div>
        <div className="flex flex-wrap default-page pt-8 pb-16">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto mb-8 text-grey-darker text-center">
            <h1
              dangerouslySetInnerHTML={{
                __html: currentPage.pages.h1Title
                  ? currentPage.pages.h1Title
                  : currentPage.title,
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 xl:grid-cols-4 lg:gap-x-4 gap-y-6 mb-12 mx-auto px-4 xl:px-0 max-w-5xl">
          {currentPage.layout.sections.map((section, index) => {
            const emphasizedBackground = section.emphasizedProduct
              ? `${process.env.THEME_COLOR_PRIMARY}-darker`
              : "grey-darkest";
            return (
              <div
                className={`flex flex-wrap justify-center text-grey-darkest`}
                key={index}
              >
                <div className="relative flex flex-col rounded border shadow-md">
                  <div className="flex-1 pb-12">
                    {section.emphasizedProduct && (
                      <p
                        className={`absolute top-0 -translate-y-1/2 transform rounded-full bg-${process.env.THEME_COLOR_PRIMARY} py-1.5 px-4 text-sm font-semibold text-white`}
                      >
                        Most popular
                      </p>
                    )}
                    <h2
                      className={`text-xl font-medium tracking-wide bg-${emphasizedBackground} text-white w-full text-center py-8`}
                    >
                      <Link
                        to={section.sectionTitleLink}
                        className="text-white no-underline"
                      >
                        {section.sectionTitle}
                      </Link>
                    </h2>
                    <div className="w-full">
                      <Link to={section.sectionTitleLink}>
                        <img
                          src={
                            section.photo && section.photo.localFile.publicURL
                          }
                          className="w-full lg:h-72"
                          alt={section.photo && section.photo.alt}
                          title={section.photo && section.photo.title}
                        />
                      </Link>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-3 p-6">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section.sectionBody,
                        }}
                      />
                      <Link
                        to={section.buttonLink}
                        className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded uppercase shadow text-center`}
                      >
                        {section.buttonText}
                      </Link>
                      <p
                        className={`mt-2 text-${process.env.THEME_COLOR_PRIMARY}-dark text-base leading-tight`}
                      >
                        <small>{section.postButtonText}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
export default ProductComparisonPageTemplate;
