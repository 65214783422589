import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

function BlogPageTemplate(props) {
  return (
    <StaticQuery
      query={blogQuery}
      render={(data) => {
        const { edges } = data.allWpPost;
        return (
          <Layout>
            <Seo
              title={props.currentPage.seo.title}
              description={props.currentPage.seo.metaDesc}
            />
            <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
              <h1
                className="m-auto"
                dangerouslySetInnerHTML={{
                  __html: props.currentPage.pages.h1Title
                    ? props.currentPage.pages.h1Title
                    : props.currentPage.title,
                }}
              />
            </div>
            <div className="max-w-2xl m-auto flex flex-wrap pb-16">
              <div
                className="w-full p-4 lg:px-8 lg:py-0 mb-8 text-grey-darker max-w-2xl wp-content"
                dangerouslySetInnerHTML={{
                  __html: props.currentPage.content,
                }}
              />
            </div>
            <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
              <div className="w-full p-4 md:p-0">
                {edges.map((data, index) => {
                  return (
                    <Link
                      className={`inline-block max-w-sm relative px-4 py-6 border border-grey-dark mb-6 cursor-pointer text-white ${process.env.THEME_COLOR_PRIMARY}-overlay no-underline text-white`}
                      key={index}
                      to={`/${data.node.slug}/`}
                      style={{
                        backgroundImage: `url(${data.node.featuredImage.node.localFile.publicURL})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    >
                      <div className="relative z-50">
                        <h2 className="hover:underline">{data.node.title}</h2>
                        <p className="italic text-sm">{data.node.date}</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.node.excerpt,
                          }}
                          className="pt-4"
                        />
                        <p className="pt-2 underline">Read More</p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </Layout>
        );
      }}
    />
  );
}

const blogQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          date(formatString: "MMM DD, YYYY")
          title
          slug
          content
          excerpt
          featuredImage {
            node {
              title
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPageTemplate;
