import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

function AccessoriesPageTemplate({ currentPage }) {
  return (
    <Layout>
      <Seo
        title={currentPage.seo.title}
        description={currentPage.seo.metaDesc}
      />
      <div>
        <div className="flex flex-wrap default-page pt-8 pb-16">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto mb-8 text-grey-darker text-center">
            <h1
              dangerouslySetInnerHTML={{
                __html: currentPage.pages.h1Title
                  ? currentPage.pages.h1Title
                  : currentPage.title,
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          </div>
        </div>
        {currentPage.layout.sections.map((section, index) => {
          const bgColor = index % 2 === 1 ? "bg-grey-lighter" : "bg-white";
          const flexDirection =
            section.photoPosition === "right" ? "" : "flex-row-reverse";
          return (
            <div
              className={`flex flex-wrap justify-center items-center ${flexDirection} ${bgColor} text-grey-darkest`}
              key={index}
            >
              <div className="w-full md:w-1/2 py-16 px-4 md:px-16">
                <h2 className="mb-4">
                  <Link
                    to={section.sectionTitleLink}
                    className="text-grey-darkest"
                  >
                    {section.sectionTitle}
                  </Link>
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: section.sectionBody }}
                />
                <Link
                  to={section.buttonLink}
                  className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded uppercase shadow`}
                >
                  {section.buttonText}
                </Link>
                <p
                  className={`mt-2 text-${process.env.THEME_COLOR_PRIMARY}-dark text-base leading-tight`}
                >
                  <small>{section.postButtonText}</small>
                </p>
              </div>
              <div className="w-full md:w-1/2">
                <Link to={section.sectionTitleLink}>
                  <img
                    src={section.photo && section.photo.localFile.publicURL}
                    className="w-full h-auto"
                    alt={section.photo && section.photo.alt}
                    title={section.photo && section.photo.title}
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
export default AccessoriesPageTemplate;
